<template>
  <Page :hasBg="false" class="autonym-content">
    <!-- 导航栏 -->
    <NavBar
      title="实名认证"
      @clickRight="reLogin"
      @clickLeft="handleRouterBack"
      :customClickLeft="true"
      :leftArrow="ifShowLeftArrow"
    >
      <template #right>
        <p class="text_primary" v-if="!$route.query.action">重新登录</p>
      </template>
    </NavBar>

    <div class="h-full overflow-scroll bg-white">
      <div class="p-12">
        <div class="flex p-8 rounded-4" style="background: rgba(255, 138, 33, 0.1);">
          <img
            src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yuangong_xiaochengxu/tishi.svg"
            class="w-12 h-12 mt-2"
          />
          <span class="ml-4 text-warning text-12">
            严格杜绝车队长或司机亲属代替司机本人进行实名认证，违规操作造成的后果自负！
          </span>
        </div>
      </div>
      <!-- 进度条 -->
      <TosbStep class="pb-0 step pa-16" :steps="steps" :current="step" />

      <!-- 动态组件 -->
      <div class="pt-34">
        <component v-if="step > -1" :is="currentStepComponent"></component>
      </div>
    </div>
  </Page>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import NavBar from '@/components/NavBar/index.vue';
import TosbStep from '@/components/Step/index.vue';
import ClientApp from '@/client/index';
import { computed, defineComponent, onBeforeUnmount, ref, onMounted, onBeforeMount } from '@vue/composition-api';
import { Button, Dialog } from 'vant';
import { useUserVerifiedStore } from '@/store/verified';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    NavBar,
    TosbStep,
    Step1: () => import('./components/step1'),
    Step2: () => import('./components/step2'),
    Step3: () => import('./components/step3'),
    Button
  },
  setup(_, ctx) {
    const useVertifiedStore = useUserVerifiedStore();

    // 是否显示返回箭头
    const ifShowLeftArrow = ref(true);
    // 当前进度条进度
    const step = computed(() => useVertifiedStore?.verifiedInfo?.step);

    const currentStepComponent = computed(() => {
      return `Step${step.value + 1}`;
    });

    const steps = ['身份证', '人脸拍照', '认证结果'];

    onBeforeMount(async () => {
      // 不需要去load 实名数据
      if (ctx.root.$route.query?.action === 'reAuth') {
        useVertifiedStore.updateInfo({
          step: 0
        });
        //   try {
        //     await useVertifiedStore.queryUserVerifiedInfo();
        //     console.log(useVertifiedStore?.verifiedInfo?.authStatus);
        //     // if (useVertifiedStore?.verifiedInfo?.authStatus === null) {
        //     // TODO: 为验证通过但是有数据的特殊情况 特殊情况正常添加数据时不会出现
        //     useVertifiedStore.updateInfo({
        //       step: 0
        //     });
        //     // }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    });
    // 改变页面背景颜色
    onMounted(() => {
      document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
    });

    const reLogin = () => {
      localStorage.clear();
      sessionStorage.clear();
      ClientApp.switchAccount();
    };

    // 卸载组件前
    onBeforeUnmount(() => {
      document.querySelector('body').removeAttribute('style');
    });

    return {
      currentStepComponent,
      steps,
      reLogin,
      step,
      ifShowLeftArrow,
      handleRouterBack: () => {
        // 重新实名认证返回也弹框
        const { $route } = ctx.root;
        if ($route.query.action === 'reAuth' && step.value === 0) {
          Dialog.confirm({
            message: '是否确定放弃实名认证？'
          })
            .then(() => {
              ctx.root.$routerBack.call(ctx.root._router);
            })
            .catch(err => console.log(err));
          return;
        }

        // 如果状态为【待审核】或者【已审核】，返回回到上一页
        if (step.value === 0 || step.value === 2 || useUserVerifiedStore.getAuthStatus > 0) {
          // 如果是在客户端环境，调用客户端返回方法
          ctx.root.$routerBack.call(ctx.root._router);
        } else {
          useVertifiedStore.updateInfo({
            step: step.value - 1
          });
        }
      }
    };
  }
});
</script>
<style lang="less">
.label {
  height: 0.14rem;
  font-size: 0.14rem;
  color: #878787;
  p {
    float: left;
    line-height: 0.16rem;
  }
  span {
    color: #0076ff;
    font-size: 0.18rem;
    margin-left: 0.08rem;
    float: left;
    line-height: 0.2rem;
    display: block;
  }
}
.id-picture {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .id-card {
    width: 1.62rem;
    height: 1.22rem;
    background: #f6f8fa;
    border-radius: 0.04rem;
    overflow: hidden;
    margin-top: 0.08rem;
    .bg-img {
      width: 100%;
      display: block;
    }
    .text {
      width: 100%;
      height: 0.3rem;
      background: rgba(64, 152, 255, 0.5);
      text-align: center;
      color: #fff;
      line-height: 0.3rem;
    }
  }
}
.list {
  height: 0.48rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.01rem solid #ddd;
  input {
    width: 2rem;
    height: 100%;
    border: none;
    text-align: right;
    &::placeholder {
      color: #b4b4b4;
    }
  }
}
.validity {
  display: flex;
  justify-content: space-between;
  line-height: 0.4rem;
  margin-top: 0.12rem;
  color: #444444;
  margin-bottom: 0.08rem;
  .date {
    width: 1.55rem;
    height: 0.4rem;
    background: #f6f8fa;
    border: 0.01rem solid rgba(188, 188, 188, 0.5);
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.4rem;
    font-size: 0.16rem;
    color: #0076ff;
  }
}
.van-field__error-message {
  text-align: right;
}
</style>
